import { createContext, useContext, useState, ReactNode } from 'react'

// Define the translations type
interface Translations {
  [key: string]: {
    [key: string]: string
  }
}

// Define translations
const translations: Translations = {
  en: {
    'app.title': 'Lucent Currency Converter',
    'app.lucentAmount': 'Lucent Amount',
    'app.enterAmount': 'Enter amount of Lucent',
    'app.convertTo': 'Convert To',
    'app.convert': 'Convert',
    'app.enableMulti': 'Enable Multi Conversions',
    'app.addTransactions': 'Add Transactions Together',
    'app.multiConvertInfo': 'Enabling this will allow you to convert multiple amounts at once. It will also allow you to add them together. Clicking each conversion will select it. Click "Add Transactions Together" to add them together.',
    'pricing.title': 'Choose Your Plan',
    'pricing.subtitle': 'Select the perfect plan for your needs',
    'pricing.free': 'Free',
    'pricing.premium': 'Premium',
    'pricing.perMonth': '/month',
    'pricing.tryFree': 'Try Free Version',
    'pricing.getPremium': 'Get Premium',
    'pricing.recommended': 'Recommended',
    'pricing.features.discordBot': 'Discord Bot Access',
    'pricing.features.limitedConversions': 'Limited Conversions (10 per day)',
    'pricing.features.unlimited': 'Unlimited Conversions',
    'pricing.features.realtime': 'Real-time Currency Data',
    'pricing.features.korean': 'Korean Language Mode',
    'app.supportCreator': 'Support the Creator',
    'app.supportMessage': 'If you find this tool helpful, consider supporting the creator:',
    'app.copyAddress': 'Click to copy address',
    'app.copied': 'Copied!',
    'app.networkWarning': 'Please ensure you\'re sending tokens on the correct network. Sending tokens to the wrong network will result in permanent loss.',
    'app.selectWallet': 'Select Wallet',
    'app.ethereum': 'Ethereum (ETH)',
    'app.bitcoin': 'Bitcoin (BTC)',
    'app.solana': 'Solana (SOL)',
    'app.ethDescription': 'Send ETH or any ERC-20 token',
    'app.btcDescription': 'Send Bitcoin (BTC)',
    'app.solDescription': 'Send SOL or any SPL token',
    'app.follow': 'Follow',
    'app.buyNow': 'Buy Now',
    'app.addToDiscord': 'Add to Discord',
    'app.aboutCreator': 'About Creator',
    'app.whoIAm': 'Who I Am',
    'modal.aboutCreator': "Hey, I'm FattyBagz! I'm a gamer at heart, a new dad, and a web developer who loves tinkering with cool projects. As a Throne & Liberty fan, I always found myself asking: \"What's my Lucent worth in real-world money?\"",
    'modal.soIMadeThis': "So, I made this app to find out!",
    'modal.itPulls': "It pulls live currency data and calculates the current value of your Lucent using a custom algorithm I developed.",
    'modal.ifThisHelps': "If this app helps you, consider dropping a crypto tip! Your support keeps the server running and helps me keep the lights on. Thanks a ton! ❤️",
  },
  ko: {
    'app.title': '루센트 환율 계산기',
    'app.lucentAmount': '루센트 금액',
    'app.enterAmount': '루센트 금액을 입력하세요',
    'app.convertTo': '환전할 통화',
    'app.convert': '환전',
    'app.enableMulti': '다중 환전 활성화',
    'app.addTransactions': '거래 합산하기',
    'app.multiConvertInfo': '이 기능을 활성화하면 여러 금액을 한 번에 환전할 수 있습니다. 또한 이들을 함께 더할 수 있습니다. 각 환전을 클릭하여 선택하세요. "거래 합산하기"를 클릭하여 합산하세요.',
    'pricing.title': '플랜 선택',
    'pricing.subtitle': '필요에 맞는 완벽한 플랜을 선택하세요',
    'pricing.free': '무료',
    'pricing.premium': '프리미엄',
    'pricing.perMonth': '/월',
    'pricing.tryFree': '무료 버전 사용해보기',
    'pricing.getPremium': '프리미엄 구매',
    'pricing.recommended': '추천',
    'pricing.features.discordBot': '디스코드 봇 액세스',
    'pricing.features.limitedConversions': '제한된 환전 (하루 10회)',
    'pricing.features.unlimited': '무제한 환전',
    'pricing.features.realtime': '실시간 환율 데이터',
    'pricing.features.korean': '한국어 모드',
    'app.supportCreator': '제작자 후원하기',
    'app.supportMessage': '이 도구가 도움이 되셨다면, 제작자를 후원해 주세요:',
    'app.copyAddress': '주소를 복사하려면 클릭하세요',
    'app.copied': '복사됨!',
    'app.networkWarning': '올바른 네트워크에서 토큰을 전송하고 있는지 확인하세요. 잘못된 네트워크로 전송하면 영구적인 손실이 발생할 수 있습니다.',
    'app.selectWallet': '지갑 선택',
    'app.ethereum': '이더리움 (ETH)',
    'app.bitcoin': '비트코인 (BTC)',
    'app.solana': '솔라나 (SOL)',
    'app.ethDescription': 'ETH 또는 ERC-20 토큰 전송',
    'app.btcDescription': '비트코인 (BTC) 전송',
    'app.solDescription': 'SOL 또는 SPL 토큰 전송',
    'app.follow': '팔로우',
    'app.buyNow': '구매하기',
    'app.addToDiscord': '디스코드에 추가',
    'app.aboutCreator': '제작자 소개',
    'app.whoIAm': '저는 누구일까요',
    'modal.aboutCreator': "안녕하세요, FattyBagz입니다! 저는 게이머이자 새로운 아빠가 된 웹 개발자로, 흥미로운 프로젝트를 만드는 것을 좋아합니다. Throne & Liberty 팬으로서, 저는 항상 '내가 가진 루센트의 실제 가치는 얼마일까?'라는 의문이 있었습니다.",
    'modal.soIMadeThis': "그래서 이 앱을 만들게 되었습니다!",
    'modal.itPulls': "이 앱은 실시간 환율 데이터를 가져와서 제가 개발한 알고리즘으로 루센트의 현재 가치를 계산합니다.",
    'modal.ifThisHelps': "이 앱이 도움이 되셨다면, 암호화폐로 후원해 주시면 감사하겠습니다! 여러분의 후원은 서버 운영과 유지보수에 도움이 됩니다. 감사합니다! ❤️",
  }
}

// Define the context type
interface LanguageContextType {
  isKorean: boolean
  toggleLanguage: () => void
  t: (key: string) => string
}

// Create the context
const LanguageContext = createContext<LanguageContextType | undefined>(undefined)

// Define provider props type
interface LanguageProviderProps {
  children: ReactNode
}

// Provider component
export function LanguageProvider({ children }: LanguageProviderProps) {
  const [isKorean, setIsKorean] = useState(false)

  const toggleLanguage = () => setIsKorean(prev => !prev)
  
  const t = (key: string): string => {
    const lang = isKorean ? 'ko' : 'en'
    return translations[lang]?.[key] || key
  }

  return (
    <LanguageContext.Provider value={{ isKorean, toggleLanguage, t }}>
      {children}
    </LanguageContext.Provider>
  )
}

// Custom hook
export function useLanguage(): LanguageContextType {
  const context = useContext(LanguageContext)
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider')
  }
  return context
}