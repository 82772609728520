import React, { useState } from 'react';
import LucentConverter from './components/LucentConverter';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  XMarkIcon, 
  ArrowTopRightOnSquareIcon,
  ExclamationTriangleIcon
} from '@heroicons/react/24/outline';
import { PricingComparison } from './components/PricingComparison';
import { LanguageProvider, useLanguage } from './contexts/LanguageContext'
import { LanguageToggle } from './components/LanguageToggle'

const DiscordIcon = () => (
  <svg 
    className="w-5 h-5" 
    viewBox="0 0 24 24" 
    fill="currentColor"
  >
    <path d="M20.317 4.37a19.791 19.791 0 0 0-4.885-1.515.074.074 0 0 0-.079.037c-.21.375-.444.864-.608 1.25a18.27 18.27 0 0 0-5.487 0 12.64 12.64 0 0 0-.617-1.25.077.077 0 0 0-.079-.037A19.736 19.736 0 0 0 3.677 4.37a.07.07 0 0 0-.032.027C.533 9.046-.32 13.58.099 18.057a.082.082 0 0 0 .031.057 19.9 19.9 0 0 0 5.993 3.03.078.078 0 0 0 .084-.028 14.09 14.09 0 0 0 1.226-1.994.076.076 0 0 0-.041-.106 13.107 13.107 0 0 1-1.872-.892.077.077 0 0 1-.008-.128 10.2 10.2 0 0 0 .372-.292.074.074 0 0 1 .077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 0 1 .078.01c.12.098.246.198.373.292a.077.077 0 0 1-.006.127 12.299 12.299 0 0 1-1.873.892.077.077 0 0 0-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 0 0 .084.028 19.839 19.839 0 0 0 6.002-3.03.077.077 0 0 0 .032-.054c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 0 0-.031-.03zM8.02 15.33c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.956-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.956 2.418-2.157 2.418zm7.975 0c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.955-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.946 2.418-2.157 2.418z"/>
  </svg>
);

const TwitterIcon = () => (
  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
  </svg>
);

const CoffeeIcon = () => (
  <svg 
    className="w-5 h-5 text-white/90"
    viewBox="0 0 24 24" 
    stroke="currentColor"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M17 8h1a4 4 0 1 1 0 8h-1" />
    <path d="M3 8h14v9a4 4 0 0 1-4 4H7a4 4 0 0 1-4-4V8z" />
    <line x1="6" y1="2" x2="6" y2="4" />
    <line x1="10" y1="2" x2="10" y2="4" />
    <line x1="14" y1="2" x2="14" y2="4" />
  </svg>
);

const TipBanner = () => {
  const { t } = useLanguage();
  const wallets = {
    eth: {
      address: "0x15b551DeE696A89f58C95AfF4cCB30435686c85e",
      icon: (
        <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
          <path d="M11.944 17.97L4.58 13.62 11.943 24l7.37-10.38-7.372 4.35h.003zM12.056 0L4.69 12.223l7.365 4.354 7.365-4.35L12.056 0z" />
        </svg>
      ),
      name: t('app.ethereum'),
      description: t('app.ethDescription')
    },
    sol: {
      address: "9QHKwovLUsxL4n5ac12k3HQV2LMLkprBhQGuPeKT675e",
      icon: (
        <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
          <path d="M17.147 4.21l-4.01 4.01c-.237.237-.237.62 0 .857.237.237.62.237.857 0l4.01-4.01c.237-.237.237-.62 0-.857-.237-.237-.62-.237-.857 0zm-11.02 11.02l-4.01 4.01c-.237.237-.237.62 0 .857.237.237.62.237.857 0l4.01-4.01c.237-.237.237-.62 0-.857-.237-.237-.62-.237-.857 0zm11.02 0c-.237-.237-.62-.237-.857 0-.237.237-.237.62 0 .857l4.01 4.01c.237.237.62.237.857 0 .237-.237.237-.62 0-.857l-4.01-4.01zm-11.02-11.02c-.237-.237-.62-.237-.857 0-.237.237-.237.62 0 .857l4.01 4.01c.237.237.62.237.857 0 .237-.237.237-.62 0-.857l-4.01-4.01z" />
        </svg>
      ),
      name: t('app.solana'),
      description: t('app.solDescription')
    }
  };

  const [copied, setCopied] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [showComingSoon, setShowComingSoon] = useState(false);
  const [showComingSoonText, setShowComingSoonText] = useState(false);

  const handleCopy = (address) => {
    navigator.clipboard.writeText(address);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <motion.div
      className="max-w-full w-full mx-auto"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.2 }}
    >
      <motion.div
        className="bg-white/10 border border-white/20 rounded-xl shadow-2xl p-4 
                  hover:bg-white/15 transition-all duration-300 cursor-pointer"
        onClick={() => !isExpanded && setIsExpanded(true)}
        whileHover={{ scale: isExpanded ? 1 : 1.02 }}
      >
        {!isExpanded ? (
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <CoffeeIcon />
              <span className="text-white/90 font-medium">{t('app.supportCreator')}</span>
            </div>
            <ArrowTopRightOnSquareIcon className="w-5 h-5 text-white/60" />
          </div>
        ) : (
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <span className="text-white/90 font-medium">{t('app.selectWallet')}</span>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setIsExpanded(false);
                  setSelectedWallet(null);
                }}
                className="text-white/60 hover:text-white/90 transition-colors"
              >
                <XMarkIcon className="w-5 h-5" />
              </button>
            </div>

            <div className="grid grid-cols-2 gap-2">
              {Object.entries(wallets).map(([key, wallet]) => (
                <div
                  key={key}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedWallet(key);
                  }}
                  className={`p-3 rounded-lg border transition-all cursor-pointer ${
                    selectedWallet === key
                      ? 'bg-white/10 border-purple-500/30'
                      : 'bg-white/5 border-white/10 hover:bg-white/10'
                  }`}
                >
                  <div className="flex items-center gap-2 text-white/90">
                    <span className="text-purple-400">{wallet.icon}</span>
                    <span className="text-sm font-medium">{wallet.name}</span>
                  </div>
                  <p className="text-xs text-white/60 mt-1">{wallet.description}</p>
                </div>
              ))}
            </div>

            {selectedWallet && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                className="space-y-2"
              >
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopy(wallets[selectedWallet].address);
                  }}
                  className="bg-white/5 rounded-lg p-3 text-center hover:bg-white/10 transition-colors cursor-pointer"
                >
                  <p className="text-sm text-white/75 font-mono break-all">
                    {wallets[selectedWallet].address}
                  </p>
                  <p className="text-xs text-purple-400 mt-1">
                    {copied ? t('app.copied') : t('app.copyAddress')}
                  </p>
                </div>
                <div className="flex items-start gap-2 text-xs text-amber-400/80">
                  <ExclamationTriangleIcon className="w-4 h-4 flex-shrink-0 mt-0.5" />
                  <p>{t('app.networkWarning')}</p>
                </div>
              </motion.div>
            )}
          </div>
        )}
      </motion.div>
    </motion.div>
  );
};

function AppContent() {
  const { t } = useLanguage();
  const [showModal, setShowModal] = useState(false);
  const [showPricing, setShowPricing] = useState(false);
  const [showComingSoon, setShowComingSoon] = useState(false);
  const [showComingSoonText, setShowComingSoonText] = useState(false);
  const [showAboutModal, setShowAboutModal] = useState(false);

  const openDiscordLink = () => {
    window.open(
      'https://discord.com/oauth2/authorize?client_id=1303198716701835337&permissions=67584&integration_type=0&scope=bot',
      '_blank'
    );
  };

  return (
    <div className="h-[100dvh] overflow-y-auto overscroll-none bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-purple-900 via-slate-900 to-black py-12 px-4">
      <div className="max-w-xl mx-auto">
        <LanguageToggle />
        {/* Left Side Button */}
        <div className="fixed top-4 left-4 z-40 flex flex-row gap-2">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => window.open('https://x.com/fattybagz', '_blank')}
            className="flex items-center gap-2 bg-black hover:bg-gray-900 text-white font-medium 
                     py-1.5 px-3 rounded-lg transition-colors shadow-lg shadow-black/25 text-sm sm:text-base
                     sm:py-2 sm:px-4"
          >
            <TwitterIcon />
            <span className="whitespace-nowrap">{t('app.follow')}</span>
          </motion.button>

          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setShowAboutModal(true)}
            className="flex items-center gap-2 bg-white/10 hover:bg-white/20 text-white font-medium 
                     py-1.5 px-3 rounded-lg transition-colors shadow-lg shadow-black/25 text-sm sm:text-base
                     sm:py-2 sm:px-4"
          >
            <span className="whitespace-nowrap">{t('app.aboutCreator')}</span>
          </motion.button>
        </div>

        {/* Right Side Buttons */}
        <div className="fixed top-4 right-4 flex flex-wrap gap-2 z-40 max-w-[calc(100%-5rem)]">
          <div className="relative">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onMouseEnter={() => setShowComingSoonText(true)}
              onMouseLeave={() => setShowComingSoonText(false)}
              onClick={() => setShowComingSoonText(true)}
              onTouchEnd={() => {
                setShowComingSoonText(true);
                setTimeout(() => setShowComingSoonText(false), 2000);
              }}
              className="flex items-center gap-2 bg-[#5865F2]/50 hover:bg-[#4752C4]/50 text-white/70 
                       font-medium py-1.5 px-3 rounded-lg transition-colors shadow-lg 
                       shadow-[#5865F2]/25 text-sm sm:text-base sm:py-2 sm:px-4 cursor-not-allowed"
            >
              <DiscordIcon />
              <div className="relative inline-flex items-center">
                <motion.span
                  initial={{ opacity: 1 }}
                  animate={{ opacity: showComingSoonText ? 0 : 1 }}
                  className="whitespace-nowrap"
                >
                  {t('app.addToDiscord')}
                </motion.span>
                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: showComingSoonText ? 1 : 0 }}
                  className="whitespace-nowrap absolute left-0"
                >
                  Coming Soon
                </motion.span>
              </div>
            </motion.button>
            {showComingSoon && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                className="absolute left-0 right-0 top-full mt-2 px-3 py-1.5 
                         bg-slate-900/95 backdrop-blur-lg border border-white/10 
                         text-white text-sm rounded-lg whitespace-nowrap shadow-xl z-50
                         w-fit mx-auto"
              >
                Coming Soon
              </motion.div>
            )}
          </div>

          {/* Hide Buy Now button but keep the code for future use */}
          {false && (
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setShowPricing(true)}
              className="flex items-center gap-2 bg-gradient-to-r from-purple-600 to-indigo-600 
                       hover:from-purple-700 hover:to-indigo-700 text-white font-medium py-1.5 px-3
                       rounded-lg transition-colors shadow-lg shadow-purple-500/25 text-sm sm:text-base
                       sm:py-2 sm:px-4"
            >
              <span className="whitespace-nowrap">{t('app.buyNow')}</span>
            </motion.button>
          )}
        </div>

        {/* Main content */}
        <div className="mt-8 relative z-10 w-full">
          <div className="bg-white/5 backdrop-blur-lg rounded-2xl border border-white/10 shadow-2xl p-8">
            {showPricing ? (
              <PricingComparison setShowPricing={setShowPricing} />
            ) : (
              <>
                <LucentConverter />
                <div className="mt-8">
                  <TipBanner />
                </div>
              </>
            )}
          </div>
        </div>

        {/* Modal */}
        <AnimatePresence>
          {showModal && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 flex items-center justify-center z-50 p-4"
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                backdropFilter: 'blur(8px)',
                WebkitBackdropFilter: 'blur(8px)',
                '-webkit-backdrop-filter': 'blur(8px)'
              }}
            >
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className="bg-black/80 border border-white/20 p-6 rounded-2xl max-w-md w-full relative"
              >
                <button 
                  onClick={() => setShowModal(false)}
                  className="absolute top-4 right-4 text-white/60 hover:text-white/90 transition-colors"
                >
                  <svg 
                    className="w-5 h-5" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke="currentColor" 
                    strokeWidth="2"
                    strokeLinecap="round" 
                    strokeLinejoin="round"
                  >
                    <path d="M18 6L6 18"></path>
                    <path d="M6 6l12 12"></path>
                  </svg>
                </button>

                <h2 className="text-2xl font-bold text-white mb-4">Purchase the Bot</h2>
                <p className="text-white/80 mb-6">
                  Get the full version for $1.99/month and enjoy unlimited conversions.
                </p>
                <div className="space-y-3">
                  <button
                    className="w-full bg-white/10 hover:bg-white/20 text-white font-medium py-2 px-4 
                             rounded-lg transition-all"
                    onClick={() => {
                      setShowModal(false);
                      window.open('https://discord.com/oauth2/authorize?client_id=1303198716701835337&permissions=67584&integration_type=0&scope=bot', '_blank');
                    }}
                  >
                    Try the Free Version
                  </button>
                  <button
                    className="w-full bg-gradient-to-r from-purple-600 to-pink-600 hover:from-purple-500 
                             hover:to-pink-500 text-white font-medium py-2 px-4 rounded-lg transition-all"
                    onClick={() => {
                      setShowModal(false);
                      setShowPricing(true);
                    }}
                  >
                    Buy Full Version
                  </button>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {showAboutModal && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 flex items-center justify-center z-50 p-4"
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                backdropFilter: 'blur(12px)',
                WebkitBackdropFilter: 'blur(12px)',
                '-webkit-backdrop-filter': 'blur(12px)'
              }}
            >
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className="bg-black/40 border border-white/20 p-6 rounded-2xl max-w-md w-full relative backdrop-blur-md"
              >
                <button 
                  onClick={() => setShowAboutModal(false)}
                  className="absolute top-4 right-4 text-white/60 hover:text-white/90 transition-colors"
                >
                  <XMarkIcon className="w-5 h-5" />
                </button>

                <h2 className="text-2xl font-bold text-white mb-4">{t('app.whoIAm')}</h2>
                <div className="space-y-4 text-white/80">
                  <p>{t('modal.aboutCreator')}</p>
                  <p>{t('modal.soIMadeThis')}</p>
                  <p>{t('modal.itPulls')}</p>
                  <p>{t('modal.ifThisHelps')}</p>
                  <p>- FattyBagz</p>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default function App() {
  return (
    <LanguageProvider>
      <AppContent />
    </LanguageProvider>
  );
}
