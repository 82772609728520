import React from 'react'
import { motion } from 'framer-motion'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useLanguage } from '../contexts/LanguageContext'

const PricingTier = ({ title, price, features, isRecommended, onButtonClick }) => {
  const { t } = useLanguage()

  const handlePremiumClick = () => {
    window.open('https://buy.stripe.com/3cseY3acE2r11Ne3cc', '_blank')
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className={`bg-white/5 backdrop-blur-lg border ${
        isRecommended ? 'border-purple-500/30' : 'border-white/10'
      } rounded-2xl p-6 shadow-xl relative`}
    >
      {isRecommended && (
        <div className="absolute -top-3 left-1/2 -translate-x-1/2">
          <span className="bg-gradient-to-r from-purple-600 to-pink-600 text-white text-xs font-medium px-3 py-1 rounded-full">
            {t('pricing.recommended')}
          </span>
        </div>
      )}
      <div className="text-center mb-6">
        <h3 className="text-xl font-bold text-white mb-2">{t(`pricing.${title.toLowerCase()}`)}</h3>
        <div className="text-3xl font-bold text-white mb-1">{price}</div>
        <div className="text-sm text-white/60">{t('pricing.perMonth')}</div>
      </div>
      <div className="space-y-3">
        {features.map((feature, index) => (
          <div key={index} className="flex items-center gap-3">
            {feature.included ? (
              <CheckIcon className="w-5 h-5 text-green-400" />
            ) : (
              <XMarkIcon className="w-5 h-5 text-red-400" />
            )}
            <span className="text-white/80">{t(`pricing.features.${feature.key}`)}</span>
          </div>
        ))}
      </div>
      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        onClick={isRecommended ? handlePremiumClick : onButtonClick}
        className={`w-full mt-6 py-3 px-4 rounded-lg ${
          isRecommended
            ? 'bg-gradient-to-r from-purple-600 to-pink-600 hover:from-purple-500 hover:to-pink-500'
            : 'bg-white/10 hover:bg-white/20'
        } text-white font-medium transition-all`}
      >
        {isRecommended ? t('pricing.getPremium') : t('pricing.tryFree')}
      </motion.button>
    </motion.div>
  )
}

export function PricingComparison({ setShowPricing }) {
  const { t } = useLanguage()

  const features = {
    free: [
      { key: 'discordBot', text: t('pricing.features.discordBot'), included: true },
      { key: 'limitedConversions', text: t('pricing.features.limitedConversions'), included: true },
      { key: 'realtime', text: t('pricing.features.realtime'), included: false },
      { key: 'korean', text: t('pricing.features.korean'), included: false },
    ],
    premium: [
      { key: 'discordBot', text: t('pricing.features.discordBot'), included: true },
      { key: 'unlimited', text: t('pricing.features.unlimited'), included: true },
      { key: 'realtime', text: t('pricing.features.realtime'), included: true },
      { key: 'korean', text: t('pricing.features.korean'), included: true },
    ],
  }

  return (
    <div className="relative">
      <button
        onClick={() => setShowPricing(false)}
        className="absolute -top-2 -left-2 p-2 bg-white/10 hover:bg-white/20 
                   rounded-lg transition-all duration-300 group"
      >
        <svg
          className="w-5 h-5 text-white/60 group-hover:text-white/90 transition-colors"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M19 12H5M12 19l-7-7 7-7" />
        </svg>
      </button>

      <h2 className="text-2xl font-bold text-white text-center mb-2">{t('pricing.title')}</h2>
      <p className="text-white/60 text-center mb-8">{t('pricing.subtitle')}</p>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <PricingTier
          title="Free"
          price="$0"
          features={features.free}
          isRecommended={false}
          onButtonClick={() => window.open('https://discord.com/oauth2/authorize?client_id=1303198716701835337&permissions=67584&integration_type=0&scope=bot', '_blank')}
        />
        <PricingTier
          title="Premium"
          price="$1.99"
          features={features.premium}
          isRecommended={true}
        />
      </div>
    </div>
  )
} 