import { motion } from 'framer-motion'
import { useLanguage } from '../contexts/LanguageContext'

export function LanguageToggle() {
  const { isKorean, toggleLanguage } = useLanguage()

  return (
    <motion.button
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      onClick={toggleLanguage}
      className="absolute top-[90px] right-8 px-2 py-1 rounded-lg bg-white/10 
                 hover:bg-white/20 text-white font-medium transition-all z-50
                 text-xs"
    >
      {isKorean ? 'English' : '한국어'}
    </motion.button>
  )
} 
