import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useLanguage } from '../contexts/LanguageContext';

// Load cached exchange rates from a local JSON file
const loadExchangeRates = async () => {
  const response = await fetch('/exchangeRates.json');
  return response.json();
};

const LucentConverter = () => {
  const { t } = useLanguage()
  const CONVERSION_RATE = 0.01777; // Lucent to USD rate
  const [lucentAmount, setLucentAmount] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  const [convertedAmount, setConvertedAmount] = useState(null);
  const [exchangeRates, setExchangeRates] = useState({});
  const [displayedLucent, setDisplayedLucent] = useState('');
  const [enableMultiConvert, setEnableMultiConvert] = useState(false);
  const [conversions, setConversions] = useState([]);
  const [selectedConversions, setSelectedConversions] = useState([]);
  const [showWarning, setShowWarning] = useState(false);

  // Load cached exchange rates on mount
  useEffect(() => {
    const loadRates = async () => {
      const data = await loadExchangeRates();
      setExchangeRates(data.rates);
    };
    loadRates();
  }, []);

  const handleConversion = () => {
    if (!lucentAmount) return;
    
    const usdAmount = lucentAmount * CONVERSION_RATE;
    const rate = exchangeRates[selectedCurrency];
    
    if (rate) {
      if (enableMultiConvert) {
        const newConversion = {
          id: Date.now().toString(),
          lucentAmount,
          convertedAmount: (usdAmount * rate).toFixed(2),
          currency: selectedCurrency,
          isSelected: false
        };
        
        setConversions(prev => {
          const updated = [newConversion, ...prev].slice(0, 10);
          return updated;
        });
      } else {
        setDisplayedLucent(lucentAmount);
        setConvertedAmount((usdAmount * rate).toFixed(2));
      }
      setLucentAmount('');
    }
  };

  const handleConversionSelect = (id) => {
    setConversions(prev => prev.map(conv => 
      conv.id === id ? { ...conv, isSelected: !conv.isSelected } : conv
    ));
    setSelectedConversions(prev => 
      prev.includes(id) ? prev.filter(convId => convId !== id) : [...prev, id]
    );
  };

  const handleAddTransactions = () => {
    const selected = conversions.filter(conv => conv.isSelected);
    const totalLucent = selected.reduce((sum, conv) => sum + Number(conv.lucentAmount), 0);
    const totalConverted = selected.reduce((sum, conv) => sum + Number(conv.convertedAmount), 0);
    
    const newConversion = {
      id: Date.now().toString(),
      lucentAmount: totalLucent.toString(),
      convertedAmount: totalConverted.toFixed(2),
      currency: selectedCurrency,
      isSelected: false
    };
    
    setConversions([newConversion, ...conversions.filter(conv => !conv.isSelected)]);
    setSelectedConversions([]);
  };

  return (
    <div className="space-y-6">
      <div className="text-center space-y-4">
        <img 
          src="/images/throne-and-liberty-logo.png"
          alt="Throne and Liberty" 
          className="h-32 mx-auto object-contain mb-2"
        />
        <h1 className="text-3xl font-bold text-white/90">
          {t('app.title')}
        </h1>
      </div>
      
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-white/80 mb-2">
            {t('app.lucentAmount')}
          </label>
          <div className="relative flex items-center">
            <img 
              src="/images/ICO_BMCoin_Gold_BM.png"
              alt="Lucent" 
              className="absolute left-3 w-6 h-6 rounded-full"
            />
            <input
              type="number"
              value={lucentAmount}
              onChange={(e) => setLucentAmount(e.target.value)}
              className="w-full pl-12 pr-4 py-3 rounded-lg bg-white/5 border border-white/10 
                       text-white placeholder-white/40 focus:outline-none focus:ring-2 
                       focus:ring-purple-500/50 focus:border-transparent"
              placeholder={t('app.enterAmount')}
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-white/80 mb-2">
            {t('app.convertTo')}
          </label>
          <div className="relative">
            <select
              value={selectedCurrency}
              onChange={(e) => {
                if (enableMultiConvert && conversions.length > 0) {
                  return;
                }
                setSelectedCurrency(e.target.value);
              }}
              disabled={enableMultiConvert && conversions.length > 0}
              className="w-full px-4 py-3 rounded-lg bg-white/5 border border-white/10 
                       text-white focus:outline-none focus:ring-2 focus:ring-purple-500/50 
                       focus:border-transparent backdrop-blur-sm appearance-none
                       disabled:opacity-50 disabled:cursor-not-allowed"
              style={{
                backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e")`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right 1rem center',
                backgroundSize: '1.5em'
              }}
            >
              {Object.keys(exchangeRates).map((currency) => (
                <option key={currency} value={currency}>
                  {currency}
                </option>
              ))}
            </select>
          </div>
        </div>

        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={handleConversion}
          className="w-full py-3 px-4 rounded-lg bg-gradient-to-r from-purple-600 to-pink-600 
                   hover:from-purple-500 hover:to-pink-500 text-white font-medium shadow-lg 
                   shadow-purple-500/25 border border-white/10"
        >
          {t('app.convert')}
        </motion.button>
      </div>

      <div className="mt-6 flex items-center gap-3">
        <div className="flex items-center gap-3">
          <input
            type="checkbox"
            id="multiConvert"
            checked={enableMultiConvert}
            onChange={(e) => {
              setEnableMultiConvert(e.target.checked);
              if (!e.checked) {
                setConversions([]);
                setSelectedConversions([]);
              }
            }}
          />
          <label htmlFor="multiConvert" className="text-sm text-white/80 cursor-pointer">
            {t('app.enableMulti')}
          </label>
          <div className="relative inline-block">
            <InformationCircleIcon 
              className="w-5 h-5 text-white/60 hover:text-white/90 transition-colors cursor-help"
              onMouseEnter={() => setShowWarning(true)}
              onMouseLeave={() => setShowWarning(false)}
            />
            {showWarning && (
              <div className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 w-64">
                <div className="bg-white/10 border border-white/20 rounded-xl p-3 
                              shadow-xl text-xs text-white/80">
                  {t('app.multiConvertInfo')}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Conversion Results - Single mode or Multi mode */}
      {enableMultiConvert ? (
        <div className="space-y-3">
          {conversions.map((conversion) => (
            <motion.div
              key={conversion.id}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              onClick={() => handleConversionSelect(conversion.id)}
              className={`p-4 rounded-lg border transition-all cursor-pointer
                        ${conversion.isSelected 
                          ? 'bg-green-500/10 border-green-500/30' 
                          : 'bg-white/[0.03] border-white/[0.05]'}`}
            >
              <p className="text-center text-white/90 flex items-center justify-center gap-2">
                <span className="flex items-center gap-2">
                  <img src="/images/ICO_BMCoin_Gold_BM.png" alt="Lucent" className="w-6 h-6 rounded-full" />
                  <span className="text-lg font-medium">{conversion.lucentAmount}</span>
                </span>
                <span className="text-white/60">Lucent =</span>
                <span className="text-lg font-medium">
                  {conversion.convertedAmount} {conversion.currency}
                </span>
              </p>
            </motion.div>
          ))}
          
          {selectedConversions.length > 1 && (
            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              onClick={handleAddTransactions}
              className="w-full py-3 px-4 rounded-lg bg-gradient-to-r from-purple-600 to-pink-600 
                       hover:from-purple-500 hover:to-pink-500 text-white font-medium shadow-lg 
                       shadow-purple-500/25 border border-white/10"
            >
              {t('app.addTransactions')}
            </motion.button>
          )}
        </div>
      ) : (
        convertedAmount && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            className="p-4 bg-white/[0.03] rounded-lg border border-white/[0.05]"
          >
            <p className="text-center text-white/90 flex items-center justify-center gap-2">
              <span className="flex items-center gap-2">
                <img src="/images/ICO_BMCoin_Gold_BM.png" alt="Lucent" className="w-6 h-6 rounded-full" />
                <span className="text-lg font-medium">{displayedLucent}</span>
              </span>
              <span className="text-white/60">Lucent =</span>
              <span className="text-lg font-medium">{convertedAmount} {selectedCurrency}</span>
            </p>
          </motion.div>
        )
      )}
    </div>
  );
};

export default LucentConverter;
